/**
 * Transform SVG to function component
 * @see url https://transform.tools/
 */

import React from "react";

function TubeWhy(props) {
	return (
		<svg
			width={298}
			height={155}
			viewBox="0 0 298 155"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M293.5 150.5h-23.624c-5.523 0-10-4.477-10-10v-126c0-5.523-4.477-10-10-10H4.5"
				stroke="#FCFCFC"
				strokeWidth={8}
			/>
		</svg>
	);
}

export default TubeWhy;
