import React from "react";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import "./styles.scss";
import { APPROACH_PAGE } from "../../utils/constants";
import HeadingPolygon from "./heading-polygon.svg";
import GoldenCircle from "./golden-circle.svg";
import discoverImg from "./discover.png";
import executeImg from "./execute.png";
import planImg from "./plan.png";
import ApproachSection from "../../components/ApproachSection";
import ApproachProcess from "../../components/ApproachProcess";
import data from '../../content/approachPage';

function ApproachPage() {
	const {
		intro,
		question1,
		question2,
		question3,
		why,
		how,
		what,
		discover,
		plan,
		execute,
	} = data;

	return (
		<Layout>
			<SearchEngineOptimization title={"Approach"} />
			<div className="approach">
				<section className="section section--is-spacious">
					<div className="container">
						<div className="approach__section">
							<div>
								<div className="approach__golden-circle-mobile">
									<img src={GoldenCircle} alt="" />
								</div>
								<h1 className="approach__heading">
									{APPROACH_PAGE.HEADING_LINE_1} <br />
									<span className="approach__heading--italic">{APPROACH_PAGE.HEADING_LINE_2}</span>
									<div className="approach__polygon">
										<img src={HeadingPolygon} alt="" />
									</div>
								</h1>
								<p>{intro}</p>
								<ul>
									<li className="approach__question approach__question--1">
										{question1}
									</li>
									<li className="approach__question approach__question--2">
										{question2}
									</li>
									<li className="approach__question approach__question--3">
										{question3}
									</li>
								</ul>
							</div>
							<div className="approach__golden-circle">
								<img src={GoldenCircle} alt="" />
							</div>
						</div>
					</div>
				</section>

				<ApproachSection
					sectionName="why"
					backgroundColor="green"
					title="Why?"
					description={why}
					headingLine1={APPROACH_PAGE.WHY_HEADING_LINE_1}
					headingLine2={APPROACH_PAGE.WHY_HEADING_LINE_2}
				/>

				<ApproachSection
					sectionName="how"
					backgroundColor="dark"
					title="How?"
					description={how}
					headingLine1={APPROACH_PAGE.HOW_HEADING_LINE_1}
					headingLine2={APPROACH_PAGE.HOW_HEADING_LINE_2}
				/>

				<ApproachSection
					sectionName="what"
					backgroundColor="white"
					title="What?"
					description={what}
					headingLine1={APPROACH_PAGE.WHAT_HEADING_LINE_1}
					headingLine2={APPROACH_PAGE.WHAT_HEADING_LINE_2}
				/>

				{/* PROCESS */}
				<section className="section section--is-gray section--is-spacious">
					<div className="container">
						<h2 className="approach__heading text-center">Process</h2>
						<div className="approach__processes">
							<ApproachProcess
								title={APPROACH_PAGE.DISCOVER}
								description={discover}
								image={discoverImg}
								hasLine1={true}
								isEven={false}
							/>
							<ApproachProcess
								title={APPROACH_PAGE.PLAN}
								description={plan}
								image={planImg}
								hasLine2={true}
								isEven={true}
							/>
							<ApproachProcess
								title={APPROACH_PAGE.EXECUTE}
								description={execute}
								image={executeImg}
								isEven={false}
							/>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default ApproachPage;
