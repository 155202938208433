/**
 * Transform SVG to function component
 * @see url https://transform.tools/
 */

 import React from "react";

 function TubeWhat(props) {
	 return (
		 <svg
			 width={220}
			 height={78}
			 viewBox="0 0 220 78"
			 fill="none"
			 xmlns="http://www.w3.org/2000/svg"
			 {...props}
		 >
			 <path
				 d="M219.5 73.5h-24.124c-5.523 0-10-4.477-10-10v-49c0-5.523-4.477-10-10-10H0"
				 stroke="#282828"
				 strokeWidth={8}
			 />
		 </svg>
	 );
 }
 
 export default TubeWhat;
 