const data = {
    "intro": "Marketing maven Simon Sinek was the first to explore the “Start with Why” concept and “The Golden Circle” model, challenging us to examine the emotional core of what makes employees and customers buy into a company. It starts with three simple questions, but in a specific order: ",
    "question1": "1. Why do you do what you do?",
    "question2": "2. How do you do it?",
    "question3": "3. What do you do?",
    "why": "Our WHY. Business First, Online Second.℠ The big one. It's not about the bits and bytes, or the pixels, or the keywords. It's about solving a real business problem for our clients. We don't do cool stuff for cool stuff's sake. We're listening to the client, getting to know their business and their growth strategies, and then, and only then, are suggesting the online strategies and activities that we know so well. We believe in it so much, we have it trademarked℠.",
    "how": "Our HOW. Partnerships, not Transactions. We could just take people's money and walk away, but that's pretty greasy. Not our style. If we're in the trenches with our clients, you better believe that we're forging lasting relationships with them. \n\nWe do this through our Diamond Model (no bowtie model, never a bowtie model). We're in business for a partner, not for a transaction. We're not here to nickel and dime our clients, we're here to work shoulder-to-shoulder with them to grow their businesses. ",
    "what": "Our WHAT. Somehow, this is the simple one. We BUILD ecommerce channels (read, websites) using Shopify, BigCommerce, and Shopware. We've been doing this for years, working on sites big and small. We love the nitty-gritty, the connections, the minutiae of it all. \n\nWe also GROW ecommerce channels. We like to say \"Build it and no one is coming”, you have to tell them you built something.\"  Our performance marketing digs into real-time data to drive powerful creative and marketing outcomes.",
    "discover": "We've never been accused of being quiet BUT we love to listen. It might be our most important skill. We learn about your business (with some serious active listening). We're looking for pain, potential obstacles, and your exact goals for the project. You tell the tale, we'll write it down.",
    "plan": "After a lot of listening, it's time for some action. Outlining, drafting, ideating, all that kind of stuff. If we're building a castle, this is the blueprint stage (or green print as we call it). We hand you a Master Greenprint that covers all your pain, obstacles, and goals that we heard. It also covers responsibilities, budget, and delivery timeline.",
    "execute": "Somehow, this is the easy part. We know how your business works, we've felt your pain, we have a plan. Now we just execute the plan. It's so simple. We bring our full suite of engineering, creative, and ecommerce strategists to build that castle big and strong. Your project is managed...by a project manager (huh, that makes sense). They make sure we hit that timeline and budget to a T. After that, it's all about growing the kingdom."
}


export default data;