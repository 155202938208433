import React from "react";
import PropTypes from "prop-types";
import Discover from "./discover.svg";
import Plan from "./plan.svg";
import Execute from "./execute.svg";
import "./styles.scss";

const ApproachProcess = ({
	title,
	description,
	image,
	hasLine1,
	hasLine2,
	isEven,
}) => {
	return (
		<div className="approach-process">
			<div
				className={`approach-process__info ${
					isEven ? "approach-process__info--even" : "approach-process__info--odd"
				}`}
			>
				<h3 className="approach-process__heading">
					<div className="approach-process__heading-text">{title}</div>
					<div className="approach-process__polygon">
						{title === "Discover" && <img src={Discover} alt="" />}
						{title === "Plan" && <img src={Plan} alt="" />}
						{title === "Execute" && <img src={Execute} alt="" />}
					</div>
				</h3>
				<p>{description}</p>
			</div>
			<div
				className={`approach-process__image ${
					isEven ? "approach-process__image--even" : "approach-process__image--odd"
				}`}
			>
				<img src={image} alt={title} />
				{hasLine1 && (
					<div className="approach-process__line-1">
						<svg
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 807 1089"
						>
							<path
								d="M615.5 184C928.25 553.6 10 10 10 472"
								stroke="#282828"
								fill="transparent"
								strokeDasharray="20"
								strokeWidth="14"
							/>
						</svg>
					</div>
				)}
				{hasLine2 && (
					<div className="approach-process__line-2">
						<svg
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 807 1089"
						>
							<path
								d="M 10 184 C 10 492, 928.25 10, 615.5 472"
								stroke="#282828"
								fill="transparent"
								strokeDasharray="20"
								strokeWidth="14"
							/>
						</svg>
					</div>
				)}
			</div>
		</div>
	);
};

export default ApproachProcess;

ApproachProcess.propTypes = {
	title: PropTypes.oneOf(["Discover", "Plan", "Execute"]).isRequired,
	description: PropTypes.string.isRequired,
	image: PropTypes.any.isRequired,
	hasLine1: PropTypes.bool,
	hasLine2: PropTypes.bool,
	isEven: PropTypes.bool,
};
