import React from "react";
import PropTypes from "prop-types";
import GoldenCircleWhy from "./golden-circle-why.svg";
import GoldenCircleWhat from "./golden-circle-what.svg";
import GoldenCircleHow from "./golden-circle-how.svg";
import TubeWhy from "./TubeWhy";
import TubeHow from "./TubeHow";
import TubeWhat from "./TubeWhat";
import "./styles.scss";

const ApproachSection = ({
	sectionName,
	backgroundColor,
	title,
	description,
	headingLine1,
	headingLine2,
}) => {
	return (
		<section
			className={`section section--is-spacious section--is-${backgroundColor}`}
		>
			<div className="container">
				<div
					className={`approach-section approach-section__section--${sectionName}`}
				>
					<div>
						<div
							className={`text text--xlarge text--weight-bold ${
								backgroundColor === "green" ? "text--white" : "text--green"
							}`}
						>
							{title}
						</div>
						<h2 className="approach-section__subheading">
							{headingLine1} <br /> {headingLine2}
							{sectionName === "why" && (
								<span className="approach-section__service-marl">&#8480;</span>
							)}
						</h2>
						<p>{description}</p>
					</div>
					<div>
						<div className="approach-section__golden-circle">
							{sectionName === "why" && (
								<>
									<img src={GoldenCircleWhy} alt="" />
									<div className="approach-section__golden-circle-tube-why">
										<TubeWhy />
									</div>
								</>
							)}
							{sectionName === "how" && (
								<>
									<img src={GoldenCircleHow} alt="" />
									<div className="approach-section__golden-circle-tube-how">
										<TubeHow />
									</div>
								</>
							)}
							{sectionName === "what" && (
								<>
									<img src={GoldenCircleWhat} alt="" />
									<div className="approach-section__golden-circle-tube-what">
										<TubeWhat />
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

ApproachSection.propTypes = {
	sectionName: PropTypes.oneOf(["why", "how", "what"]).isRequired,
	backgroundColor: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	headingLine1: PropTypes.string.isRequired,
	headingLine2: PropTypes.string.isRequired,
};

export default ApproachSection;
